import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing CREATE operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-create-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-create-operation",
        "aria-label": "implementing create operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing CREATE operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-implement-server-side"
        }}>{`1. Implement server-side`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-create-a-client"
        }}>{`2. Create a client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-implement-client-side"
        }}>{`3. Implement client-side`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-create-a-test-file"
        }}>{`4. Create a test file`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#5-register-a-serverextension"
        }}>{`5. Register a ServerExtension`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#6-test-creating-a-blog-post"
        }}>{`6. Test creating a blog post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#whats-next"
        }}>{`What's next`}</a></li>
    </ul>
    <p>{`In the previous step, we defined empty service methods.
In this step, we'll fill in one of the service methods to create a blog post and implement a corresponding client method.
Also, we'll try making a call to the service method with test code using Armeria's `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{`.`}</p>
    <TutorialSteps current={3} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/thrift"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/define-service#3-compile-the-thrift-file"
        }}>{`Generated Java code`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceImpl.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-implement-server-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-implement-server-side",
        "aria-label": "1 implement server side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Implement server-side`}</h2>
    <p>{`Let's implement a service method to create a blog post.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`BlogServiceImpl`}</inlineCode>{` class, create an ID generator to issue temporary blog post IDs and a map to contain blog posts.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceImpl.java",
            "filename": "BlogServiceImpl.java"
          }}>{`import java.util.Map;
import java.util.concurrent.ConcurrentHashMap;
import java.util.concurrent.atomic.AtomicInteger;

public class BlogServiceImpl implements BlogService.AsyncIface {
  private final AtomicInteger idGenerator = new AtomicInteger();
  private final Map<Integer, BlogPost> blogPosts = new ConcurrentHashMap<>();
  ...
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`createBlogPost()`}</inlineCode>{` method, create a `}<inlineCode parentName="p">{`BlogPost`}</inlineCode>{` object with a generated ID and request parameters.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceImpl.java",
            "filename": "BlogServiceImpl.java"
          }}>{`import java.time.Instant;
...
@Override
public void createBlogPost(CreateBlogPostRequest request, AsyncMethodCallback<BlogPost> resultHandler)
        throws TException {
  final int id = idGenerator.getAndIncrement();
  final Instant now = Instant.now();
  final BlogPost blogPost = new BlogPost()
          .setId(id)
          .setTitle(request.getTitle())
          .setContent(request.getContent())
          .setModifiedAt(now.toEpochMilli())
          .setCreatedAt(now.toEpochMilli());
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`createBlogPost()`}</inlineCode>{` method, save the post information in the `}<inlineCode parentName="p">{`blogPosts`}</inlineCode>{` map and return the information of the created blog post to the `}<inlineCode parentName="p">{`resultHandler`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceImpl.java",
            "filename": "BlogServiceImpl.java"
          }}>{`@Override
public void createBlogPost(CreateBlogPostRequest request, AsyncMethodCallback<BlogPost> resultHandler)
        throws TException {
  ...
  blogPosts.put(id, blogPost);
  final BlogPost stored = blogPost;
  resultHandler.onComplete(stored);
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "2-create-a-client",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-create-a-client",
        "aria-label": "2 create a client permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Create a client`}</h2>
    <p>{`Let's create a client to send a request to the service.
You can refer to the full version of the file `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/thrift/src/main/java/example/armeria/server/blog/thrift/BlogClient.java"
      }}>{`here`}</a>{` of the file.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a class for our client. We'll name the class `}<inlineCode parentName="p">{`BlogClient`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogClient.java",
            "filename": "BlogClient.java"
          }}>{`package example.armeria.server.blog.thrift;

import org.slf4j.Logger;
import org.slf4j.LoggerFactory;

public class BlogClient {
  private static final Logger logger = LoggerFactory.getLogger(BlogClient.class);
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`BlogClient`}</inlineCode>{` class, add a constructor and create a Thrift client instance using Armeria's `}<a parentName="p" {...{
            "href": "type://ThriftClients:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/thrift/ThriftClients.html"
          }}>{`type://ThriftClients`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogClient.java",
            "filename": "BlogClient.java"
          }}>{`import java.net.URI;
import com.linecorp.armeria.client.thrift.ThriftClients;
import example.armeria.blog.thrift.BlogService;

public class BlogClient {
  ...
  private final BlogService.Iface blogService;

  BlogClient(URI uri, String path) {
    blogService =
      ThriftClients.builder(uri)
        .path(path)
        .build(BlogService.Iface.class);
  }
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "3-implement-client-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-implement-client-side",
        "aria-label": "3 implement client side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Implement client-side`}</h2>
    <p>{`In the `}<inlineCode parentName="p">{`BlogClient`}</inlineCode>{` class, add a method to send a request to create a blog post.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogClient.java",
        "filename": "BlogClient.java"
      }}>{`import org.apache.thrift.TException;
import example.armeria.blog.thrift.BlogPost;
import example.armeria.blog.thrift.CreateBlogPostRequest;
...
BlogPost createBlogPost(String title, String content) throws TException {
  final CreateBlogPostRequest request =
          new CreateBlogPostRequest().setTitle(title)
                                     .setContent(content);
  return blogService.createBlogPost(request);
}
`}</code></pre>
    <h2 {...{
      "id": "4-create-a-test-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-create-a-test-file",
        "aria-label": "4 create a test file permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Create a test file`}</h2>
    <p>{`Let's start writing test code.
We'll use test code to verify what we implement along the way.`}</p>
    <p>{`Create a file, `}<inlineCode parentName="p">{`BlogServiceTest.java`}</inlineCode>{`, under `}<inlineCode parentName="p">{`{project_root}/src/test/java/example/armeria/server/blog/thrift`}</inlineCode>{` as follows.
You can see the full version of the file `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/thrift/src/test/java/example/armeria/server/blog/thrift/BlogServiceTest.java"
      }}>{`here`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`package example.armeria.server.blog.thrift;

class BlogServiceTest {}
`}</code></pre>
    <h2 {...{
      "id": "5-register-a-serverextension",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-register-a-serverextension",
        "aria-label": "5 register a serverextension permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Register a ServerExtension`}</h2>
    <p>{`Armeria's `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{` automatically handles set-up and tear-down of a server for testing.
This is convenient as it eliminates the need to execute the main method to set up a server before running our tests.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, register a `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{` as follows.
Note that the service instance is added to the configuration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`import org.junit.jupiter.api.extension.RegisterExtension;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.thrift.THttpService;
import com.linecorp.armeria.testing.junit5.server.ServerExtension;
...
@RegisterExtension
static final ServerExtension server = new ServerExtension() {
  @Override
  protected void configure(ServerBuilder sb) throws Exception {
    sb.service("/thrift",
               THttpService.builder()
                           // Add the service to the configuration
                           .addService(new BlogServiceImpl())
                           .build());
  }
};
`}</code></pre>
    <h2 {...{
      "id": "6-test-creating-a-blog-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#6-test-creating-a-blog-post",
        "aria-label": "6 test creating a blog post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Test creating a blog post`}</h2>
    <p>{`Let's test if we can create a blog post.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, add a test method as follows.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`import static org.assertj.core.api.Assertions.assertThat;
import org.apache.thrift.TException;
import org.junit.jupiter.api.Test;
import example.armeria.blog.thrift.BlogPost;
...
@Test
void createBlogPost() throws TException {
  final BlogClient client = new BlogClient(server.httpUri(), "/thrift");
  final BlogPost response = client.createBlogPost("My first blog", "Hello Armeria!");
  assertThat(response.getId()).isGreaterThanOrEqualTo(0);
  assertThat(response.getTitle()).isEqualTo("My first blog");
  assertThat(response.getContent()).isEqualTo("Hello Armeria!");
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run the test case on your IDE or using Gradle.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`./gradlew test
`}</code></pre>
        <p parentName="li">{`The service worked as expected if you see the test case passed.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, we've implemented a method for creating a blog post.
We've also written test code and registered `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{` to our test.`}</p>
    <p>{`Next, at `}<a parentName="p" {...{
        "href": "/tutorials/thrift/blog/implement-read"
      }}>{`Step 4. Implement READ`}</a>{`, we'll implement a READ operation to read a single post and also multiple posts.`}</p>
    <TutorialSteps current={3} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      